.mainLayout {
  display: flex;
  height: 100vh;
  background-color: var(--dark);
}

.main__layout__full{
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 2000;
}

.mainLayout__col {
  flex: 1;
  padding: 1.875rem;
  width: 50%;
}

.mainLayout__col:not(:last-child) {
  border-right: 3px solid var(--dark);
}
