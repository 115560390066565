.preview {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.preview__scroll {
  overflow-x: unset;
  overflow-y: auto;
  word-wrap: break-word;
  word-break: break-all;
}