/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;0,900;1,400;1,700&display=swap');

/* Colors */
:root {
  --dark: #22303C;
  --darker: #192734;
  --darkest: #15202B;
  --contrast: #8899AC;
  --light: #f3f3f3;
  --black: #000
  --white: #FFF
}

/* General Styles */
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: var(--darkest); */
  background-color: var(--dark); 
  color: white;
}

*, *:before, *:after {
  box-sizing: border-box;
}

/* Typography */
p {
  margin: 0 0 1.3rem;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

h1 {
  font-size: 2.625em;
  line-height: 1.55;
  font-weight: 900;
}

h2 {
  font-size: 2em;
  line-height: 1.58;
  font-weight: 900;
}

h3 {
  font-size: 1.625em;
  line-height: 1.62;
  font-weight: 900;
}

h4 {
  font-size: 1.25em;
  line-height: 1.65;
  font-weight: 900;
}

h5 {
  font-size: 1em;
  line-height: 1.65;
  font-weight: 700;
}

h6 {
  font-size: 0.813em;
  line-height: 1.77;
  font-weight: 700;
}

/* Anchors */
a {
  color: white;
}

/* Buttons */
button {
  /* --button-bg: white;
  --border-bg: white; */
  --button-bg: var(--darkest);
  --border-bg: var(--darkest);
  --text-color: var(--white);
  display: inline-block;
  width: fit-content;
  border: none;
  outline: none;
  background-color: var(--button-bg);
  color: var(--text-color);
  border: 2px solid var(--border-bg);
  transition: .2s opacity;
  padding: 0.5em 1.5em;
  cursor: pointer;
  border-radius: 0.8125rem;
  font-weight: 700;
}

button:hover {
  opacity: 0.9;
}

/* Horizontal Line */
.titleBar__wrap hr {
  border: none;
  height: 3px;
  background-color: var(--dark);
}

/* Utilities */
.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-4 {
  margin-bottom: 1.5rem;
}
